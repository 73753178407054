<template>
  <div class="container">
    <pcPublicHeader :pageKey="'home'" />
    <div class="public-content">
      <div class="login-container">
        <div class="left-item">
          <div class="page-title">欢迎登录{{ websiteInfo.SiteName }}</div>
          <div class="sbu-title">畅享多重管道收益</div>
          <div class="img img-cover"><img src="../../assets/pc-images/login-img.png" /></div>
        </div>
        <div class="right-item">
          <div class="forget-title">修改/重置密码</div>
          <div class="input-item">
            <div class="input"><input v-model="from.Phone" type="text" placeholder="用户名/手机号" /></div>
          </div>
          <div class="input-item">
            <div class="input"><input v-model="from.CaptchaValue" type="text" placeholder="请输入验证码" /></div>
            <div class="code-img img-cover" @click="handleGetCaptcha">
              <img :src="captchaPath" />
            </div>
          </div>
          <div class="input-item">
            <div class="input"><input v-model="from.Vcode" type="text" placeholder="请输入短信验证码" /></div>
            <div class="send-code" :class="time > 0 ? 'not' : ''" @click="getMsg">{{ time > 0 ? `${time}s` : '发送验证码' }}</div>
          </div>
          <div class="input-item">
            <div class="input"><input v-model="from.Pwd" :type="showPwd ? 'text' : 'password'" placeholder="请输入密码" /></div>
            <div class="eye-item img-cover" @click="showPwd = !showPwd">
              <img v-if="showPwd" src="../../assets/pc-images/eye-icon.png" />
              <img v-else src="../../assets/pc-images/eye-close-icon.png" />
            </div>
          </div>
          <div class="input-item">
            <div class="input"><input v-model="from.SurePwd" :type="showSurePwd ? 'text' : 'password'" placeholder="请再次输入密码" /></div>
            <div class="eye-item img-cover" @click="showSurePwd = !showSurePwd">
              <img v-if="showSurePwd" src="../../assets/pc-images/eye-icon.png" />
              <img v-else src="../../assets/pc-images/eye-close-icon.png" />
            </div>
          </div>
          <div class="btn-item public-btn" @click="handleSubmit">
            <van-loading v-if="submiting" color="#ffffff" />
            <span v-else>确认修改</span>
          </div>
          <div class="back-item">
            <router-link :to="{ path: '/login' }">返回登录界面</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import pcPublicHeader from '../../components/pcPublicHeader.vue'
import { mapActions, mapState } from 'vuex'
 
export default {
  name: "Forget",
  components: {
    pcPublicHeader,
  },
  data() {
    return {
      submiting: false,
      captchaPath: '',
      timer: null,
      time: 0,
      showPwd: false,
      showSurePwd: false,
      from: {
        Phone: '',
        CaptchaValue: '',
        Vcode: '',
        Pwd: '',
        SurePwd: '',
      }
    }
  },
  watch: {
    
  },
  computed: {
    ...mapState(['websiteInfo'])
  },
  mounted () {
    this.handleGetCaptcha()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleShowPwd(key, value) {
      this[key] = value
    },
    handleGetCaptcha() {
      this.captchaPath = this.$HTTP.baseURL + '/common/captcha?v=' + (new Date().getTime())
    },
    async getMsg() {
      const { from, time, timer } = this
      if (time > 0 || timer != null) {
        return
      }
      if (!from.Phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!from.CaptchaValue) {
        this.$toast('请输入验证码')
        return
      }
      var res = await this.$API.sendMsg.post({
        Phone: from.Phone,
        CaptchaValue: from.CaptchaValue,
      })
      if (res.error == 0) {
        this.setTimer()
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
        this.handleGetCaptcha()
      }
    },
    setTimer() {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
      let t = 60
      this.time = t
      this.timer = setInterval(() => {
        t--
        this.time = t
        if (t <= 0) {
          clearInterval(this.timer)
        }
      }, 1000);
    },
    async handleSubmit() {
      const { from } = this
      if (!from.Phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!from.Vcode) {
        this.$toast('请输入验证码')
        return
      }
      if (!from.Pwd) {
        this.$toast('请输入新密码')
        return
      }
      if (from.Pwd.length < 6 || from.Pwd.length > 20) {
        this.$toast('请设置6-20位密码')
        return
      }
      if (!from.SurePwd) {
        this.$toast('请再次输入新密码')
        return
      }
      if (from.SurePwd.length < 6 || from.SurePwd.length > 20) {
        this.$toast('请设置6-20位密码')
        return
      }
      if (from.Pwd != from.SurePwd) {
        this.$toast('两次输入密码不一致')
        return
      }
      this.submiting = true
      var res = await this.$API.forgetPwd.post({
        Phone: from.Phone,
        Code: from.Vcode,
        Pwd: from.Pwd,
      })
      this.submiting = false
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.$router.back()
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.login-container {
  padding: 0 40px 0 52px;
  min-height: calc(100vh - 66px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-item {
    width: 560px;
    height: 542px;
    padding-top: 60px;
    .page-title {
      font-size: 32px;
      font-weight: bold;
      color: #3d3d42;
      line-height: 46px;
    }
    .sbu-title {
      line-height: 22px;
      margin-top: 6px;
      color: #9fafc1;
    }
    .img {
      width: 560px;
      height: 428px;
      margin-top: 12px;
    }
  }
  .right-item {
    width: 420px;
    padding: 60px 60px 38px 60px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, .4);
    .forget-title {
      font-size: 24px;
      font-weight: bold;
      color: #3d3d42;
      line-height: 34px;
      margin-bottom: 48px;
    }
    .input-item {
      border: 1px solid #e9edf5;
      border-radius: 6px;
      height: 42px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      margin-bottom: 20px;
      .input {
        flex: 1;
        height: 40px;
        padding: 0 20px;
        input {
          color: #3d3d42;
        }
      }
      .eye-item {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
      }
      .code-img {
        width: 120px;
        height: 42px;
        cursor: pointer;
      }
      .send-code {
        font-size: 14px;
        color: #6a5ffe;
        margin-right: 20px;
        &.not {
          color: #999;
        }
      }
    }
    .btn-item {
      cursor: pointer;
      width: 100%;
      height: 44px;
      border-radius: 6px;
      background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
      color: #fff;
      text-align: center;
      line-height: 44px;
      font-size: 14px;
      font-weight: bold;
      margin-top: 60px;
    }
    .back-item {
      line-height: 22px;
      text-align: center;
      margin-top: 30px;
      a {
        color: #6a5ffe;
        font-size: 16px;
      }
    }
  }
}
</style>
